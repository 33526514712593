// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationBrochureNewForm from "./components/LocationBrochureNewForm.res.js";
import * as LocationsDashboardNavbar from "../../../locations/dashboard/index/components/navbar/LocationsDashboardNavbar.res.js";
import * as LocationBrochureNewScss from "./LocationBrochureNew.scss";

var css = LocationBrochureNewScss;

function LocationBrochureNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsDashboardNavbar.make, {
                      selectedCategory: "NewBrochure"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "New Location Brochure"
                            }),
                        JsxRuntime.jsx(LocationBrochureNewForm.make, {
                              providers: props.providers,
                              locations: props.locations
                            })
                      ]
                    })
              ]
            });
}

var $$default = LocationBrochureNew$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
